import React, { useState, useEffect } from "react";
import { message } from 'antd';
import dayjs from 'dayjs';
import { getAccountings, getAccountingsGraph, getAccountingsCircularGraph } from 'enl-controllers/accounting';
import { getPayments, getInfoAccounting } from 'enl-controllers/payments';
import accountingContext from './accountingContext';

const Pagination = {
  pageSize: 40, // Número de elementos por página
  current: 1, // Página actual
  total: 0, // Total de elementos
};

const StateAccounting = ({ children }) => {
  const [DataAccounting, setDataAccounting] = useState([]);
  const [accountingCopy, setAccountingCopy] = useState([]);
  const [DataPayment, setDataPayment] = useState([]);
  const [paymentCopy, setPaymentCopy] = useState([]);
  const [accountsByMethod, setAccountsByMethod] = useState([]);
  const [accountsByMethodCopy, setAccountsByMethodCopy] = useState();
  const [dataType, setDataType] = useState('Summary');
  const [income, setIncome] = useState();
  const [expense, setExpense] = useState();
  const [fee, setFee] = useState();
  const [chartData2, setChartData2] = useState([]);
  const [filterByRange, setFilterByRange] = useState(null);
  const [filterByYear, setFilterByYear] = useState(null);
  const [dateFilter, setDataFilter] = useState(null);
  const [rangeFilter, setRangeFilter] = useState(null);
  const [filterByMethod, setFilterByMethod] = useState('Cash');
  const [filterByContract, setFilterByContract] = useState();
  const [filterBytStatus, setFilterBytStatus] = useState();
  const [filterByContractStatus, setFilterByContractStatus] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [paymentPageNumber, setPaymentPageNumber] = useState(1);
  const [summaryPagination, setSummaryPagination] = useState(Pagination);
  const [paymentPagination, setPaymentPagination] = useState(Pagination);
  const [pagination, setPagination] = useState(Pagination);
  const [totalIncome, setTotalIncome] = useState();
  const [totalExpenses, setTotalExpenses] = useState();
  const [totalFee, setTotalFee] = useState();
  const [totalReceivable, setTotalReceivable] = useState();
  const [totalByMethod, setTotalByMethod] = useState();
  const [payableContract, setPayableContract] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='black'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
        style={{
          fontSize: '1em',
          fontWeight: 'bold',
        }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const getUrlParams = (number) => `pageNumber=${number}${dateFilter ? `&year=${dateFilter}` : ''}${rangeFilter ? `&startDate=${rangeFilter[0]}&endDate=${rangeFilter[1]}` : ''}`;

  const getAccountingsData = async () => {
    const urlParams = getUrlParams(pageNumber);
    const res = await getAccountings(urlParams);

    if (res?.status === 200 || res?.status === 201) {
      const { data, totalIncomeAmmount, totalExpenseAmmount, currentPage, totalItems, totalFee } = res.data.results;

      setDataAccounting([...data]);
      setAccountingCopy([...data]);
      setSummaryPagination({
        ...Pagination,
        current: currentPage, // Página actual
        total: totalItems, // Total de elementos
        onChange: (page) => setPageNumber(page),
      })

      setTotalIncome(totalIncomeAmmount);
      setTotalExpenses(totalExpenseAmmount);
      setTotalFee(totalFee);
    } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
      message.error(res.response.data.message);
    } else {
      message.error('Something unexpected has happened, please reload the page please.');
    }
  };

  const getAccountingsGraphData = async () => {
    const res = await getAccountingsGraph(`${filterByYear ? `year=${filterByYear}` : ''}`);

    if (res?.status === 200 || res?.status === 201) {
      const { results } = res.data;
      setChartData2([...results.accoutings]);
    } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
      message.error(res.response.data.message);
    } else {
      message.error('Something unexpected has happened, please reload the page please.');
    }
  };

  const accountingsCircularGraph = async () => {
    const res = await getAccountingsCircularGraph(`${filterByRange && filterByRange.length > 0 ? `startDate=${filterByRange[0]}&endDate=${filterByRange[1]}` : ''}`);

    if (res?.status === 200 || res?.status === 201) {
      const { results } = res.data;
      setIncome(results.totalIncome);
      setExpense(results.totalExpense);
      setFee(results.totalFee);
    } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
      message.error(res.response.data.message);
    } else {
      message.error('Something unexpected has happened, please reload the page please.');
    }
  };

  const getPaymentsData = async () => {
    let urlParams = getUrlParams(paymentPageNumber);
    urlParams = `${urlParams}${filterByContract ?`&contractName=${filterByContract}`: ''}${filterBytStatus ? `&statusPay=${filterBytStatus}`: ''}${filterByContractStatus ? `&contractStatus=${filterByContractStatus}`: ''}`;
    const res = await getPayments(urlParams);

    if (res?.status === 200 || res?.status === 201) {
      const { data, totalReceivable, currentPage, totalItems } = res.data.results;

      setDataPayment([...data]);
      setPaymentCopy([...data]);
      setPaymentPagination({
        ...Pagination,
        current: currentPage, // Página actual
        total: totalItems, // Total de elementos
        onChange: (page) => setPaymentPageNumber(page),
      })
      setTotalReceivable(totalReceivable);
    } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
      message.error(res.response.data.message);
    } else {
      message.error('Something unexpected has happened, please reload the page please.');
    }
  };

  const getPaymentsInfo = async () => {
    const urlParams = `${dateFilter ? `year=${dateFilter}` : ''}${rangeFilter ? `&startDate=${rangeFilter[0]}&endDate=${rangeFilter[1]}` : ''}`
    const res = await getInfoAccounting(urlParams);

    if (res?.status === 200 || res?.status === 201) {
      const { results } = res.data;
      const defaultMethodList = res.data.results[filterByMethod];
      const aux = defaultMethodList?.expense?.concat(defaultMethodList.income) || [];

      setTotalByMethod(defaultMethodList?.total || 0);
      setAccountsByMethod([...aux.sort((a,b) => dayjs(a.accountingDate).valueOf() - dayjs(b.accountingDate).valueOf())]);
      setAccountsByMethodCopy(results);
    } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
      message.error(res.response.data.message);
    } else {
      message.error('Something unexpected has happened, please reload the page please.');
    }
  };

  useEffect(() => {
    getAccountingsData();
  }, [rangeFilter, dateFilter, pageNumber]);

  useEffect(() => {
    getPaymentsData();
  }, [rangeFilter, dateFilter, filterByContract, filterBytStatus, paymentPageNumber, filterByContractStatus]);

  useEffect(() => {
    getPaymentsInfo();
  }, [rangeFilter, dateFilter]);

  useEffect(() => {
    accountingsCircularGraph();
    getAccountingsGraphData();
    getPaymentsInfo();
  }, [filterByRange, filterByYear, DataAccounting]);

  return (
    <accountingContext.Provider
      value={{
        DataAccounting, setDataAccounting,
        DataPayment, setDataPayment,
        accountsByMethod, setAccountsByMethod,
        dataType, setDataType,
        income, setIncome,
        expense, setExpense,
        fee, setFee,
        chartData2, setChartData2,
        filterByRange, setFilterByRange,
        filterByYear, setFilterByYear,
        dateFilter, setDataFilter,
        rangeFilter, setRangeFilter,
        filterByMethod, setFilterByMethod,
        filterByContract, setFilterByContract,
        filterBytStatus, setFilterBytStatus,
        filterByContractStatus, setFilterByContractStatus,
        pageNumber, setPageNumber,
        paymentPageNumber, setPaymentPageNumber,
        summaryPagination, setSummaryPagination,
        paymentPagination, setPaymentPagination,
        pagination, setPagination,
        totalIncome, setTotalIncome,
        totalExpenses, setTotalExpenses,
        totalFee, setTotalFee,
        totalReceivable, setTotalReceivable,
        totalByMethod, setTotalByMethod,
        payableContract, setPayableContract,
        accountingCopy, setAccountingCopy,
        paymentCopy, setPaymentCopy,
        accountsByMethodCopy, setAccountsByMethodCopy,
        error, setError,
        loading, setLoading,
        openModal, setOpenModal,
        editItem, setEditItem,
        renderCustomizedLabel,
        getAccountingsData, getPaymentsData,
        getPaymentsInfo
      }}
    >
      {children}
    </accountingContext.Provider>
  )

};

export default StateAccounting;