import Client from "./index";

export const getTasks = async () => {
  const res = await Client.get('/tasks/')
    .then((response) => response)
    .catch((error) => error);

  return res;
};

export const createTask = async (data) => {
  const res = await Client.post(`/tasks/`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const editTask = async (id, data) => {
  const res = await Client.put(`/tasks/${id}`, data, {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  })
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const deleteTaskController = async (id) => {
  const res = await Client.delete(`/tasks/${id}`)
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const archivingTask = async (id) => {
  const res = await Client.patch(`/tasks/${id}`, {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  })
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const unarchiveTask = async (id) => {
  const res = await Client.patch(`/tasks/${id}`, {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  })
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

//= ====================================
//  COMMENTS
//-------------------------------------

export const addComment = async (data, taskId) => {
  const res = await Client.post(`/tasks/${taskId}/comments/`, data, {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  })
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const updateComment = async (data, taskId, id) => {
  const res = await Client.put(`/tasks/${taskId}/comments/${id}`, data, {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  })
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const deleteComment = async (taskId, id) => {
  const res = await Client.delete(`/tasks/${taskId}/comments/${id}`)
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};
