import Client from "./index";

export const getNotification = async () => {
  const res = await Client.get('/notifications/')
    .then((response) => response)
    .catch((error) => error);

  return res;
};

export const creatNotification = async (data) => {
  const res = await Client.post('/notifications/', data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  })
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else 
        return response;
    })
    .catch((error) => error);

  return res;
};

export const readAllNotification = async () => {
  const res = await Client.post(
    '/notifications/read-all/',
    {},
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }
  )
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else 
        return response;
    })
    .catch((error) => error);

  return res;
};

export const deleteNotification = async (id) => {
  const res = await Client.delete(`/notifications/${id}`)
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else 
        return response;
    })
    .catch((error) => error);

  return res;
};
