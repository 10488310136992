import axios from "axios";
import API_URL from '../config';
console.log('env ',API_URL);

const Client = axios.create({
  baseURL: API_URL || 'https://backend.consultingromeroinc.com',
  headers: { 
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

Client.interceptors.response.use(null, async error => { 
  console.log('Client error ',error);
  if (error && (error?.status === 401 || error?.response?.status === 401)) {
    localStorage.clear();
    if (window.location.href.includes('/app/'))
      window.location.href = "/";
  }
  
  return error;
});

export default Client;
