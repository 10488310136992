// import { from } from 'core-js/core/array';

// Main Components
export Header from './Header/Header';
export HeaderMenu from './Header/HeaderMenu';
export Sidebar from './Sidebar';
export SidebarBig from './SidebarBig';
export BreadCrumb from './BreadCrumb/BreadCrumb';
export SourceReader from './SourceReader/SourceReader';
export PapperBlock from './PapperBlock/PapperBlock';
export SearchUi from './Search/SearchUi';
export SelectLanguage from './SelectLanguage';
// Guide
export GuideSlider from './GuideSlider';
// Form
export Notification from './Notification/Notification';
export LoginForm from './Forms/LoginForm';
export LoginFormFirebase from './Forms/LoginFormFirebase';
// Table Components
export EmptyData from './Tables/EmptyData';
// Error
export ErrorWrap from './Error/ErrorWrap';

export UseTaskTable from './TaskTable';
export CalendarCustom from './Calendar';
export InputDinamic from './InputDinamic';
