import Client from "./index";

export const getClients = async (urlParams) => {
  const res = await Client.get(`/clients?limit=40&${urlParams || ''}`)
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch(error => error);

  return res;
};

export const getAClient = async (id) => {
  const res = await Client.get(`/clients/${id}`)
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch(error => error);

  return res;
};

export const createClient = async (data) => {
  const res = await Client.post(
    "/clients/",
    data,
    {
      headers: { "Content-Type": "application/json;charset=UTF-8" }
    }
  )
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch(error => error);

  return res;
};

export const updateClient = async (data, id) => {
  const res = await Client.put(
    `/clients/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch(error => error);

  return res;
};

export const deleteClient = async (id) => {
  const res = await Client.delete(`/clients/${id}`)
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch(error => error);

  return res;
};
