import React, { useRef, useEffect, useState } from 'react';
import { Typography, Tag } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MainMenu from './MainMenu';
import useStyles from './sidebar-jss';
import RomeroConsultingLogo from '../../../public/images/RomeroConsulting2.png';

const ROLE_COLOR = {
  superAdmin: 'cyan',
  admin: 'geekblue',
  accounting: 'gold',
  user: 'green',
};

function SidebarContent(props) {
  const { classes, cx } = useStyles();
  const {
    drawerPaper,
    toggleDrawerOpen,
    loadTransition,
    leftSidebar,
    dataMenu,
    status,
    anchorEl,
    openMenuStatus,
    closeMenuStatus,
    changeStatus,
    userAttr,
    history,
  } = props;
  const [transform, setTransform] = useState(0);
  const refSidebar = useRef(null);
  const { Text, Title } = Typography;

  const setStatus = (st) => {
    switch (st) {
      case 'online':
        return classes.online;
      case 'idle':
        return classes.idle;
      case 'bussy':
        return classes.bussy;
      default:
        return classes.offline;
    }
  };

  const handleScroll = (event) => {
    setTransform(event.target.scrollTop);
  };

  useEffect(() => {
    refSidebar.current.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={cx(
        classes.drawerInner,
        !drawerPaper ? classes.drawerPaperClose : ''
      )}
    >
      <div className={classes.drawerHeader}>
        <div
          className={classes.profile}
          style={{ opacity: 1 - transform / 100, marginTop: transform * -0.3 }}
        >
          <img
            className={cx(classes.avatar, classes.bigAvatar)}
            width={100}
            src={RomeroConsultingLogo}
            alt="Romero Consulting Logo"
          />
        </div>
      </div>
      <div
        id='sidebar'
        ref={refSidebar}
        className={cx(
          classes.menuContainer,
          leftSidebar && classes.rounded,
          classes.withProfile
        )}
      >
        <div
          className={
            cx(
              classes.userNameContent,
              !drawerPaper ? classes.userNameContentClosed : ''
            )
          }
        >
          <Title className={cx(classes.userName)} strong level={3}>
            {userAttr.name} {userAttr.lastName}
          </Title>
          <Text className={cx(classes.userEmail)}>{userAttr.email}</Text>
          <Tag
            className={cx(classes.userName, classes.rol)}
            color={ROLE_COLOR[userAttr.role]}
          >
            {userAttr.role}
          </Tag>
        </div>    
        <MainMenu
          loadTransition={loadTransition}
          dataMenu={dataMenu}
          toggleDrawerOpen={toggleDrawerOpen}
          history={history}
          selectByPath={true}
        />
      </div>
    </div>
  );
}

SidebarContent.propTypes = {
  userAttr: PropTypes.object.isRequired,
  drawerPaper: PropTypes.bool.isRequired,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  leftSidebar: PropTypes.bool.isRequired,
  dataMenu: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
  openMenuStatus: PropTypes.func.isRequired,
  closeMenuStatus: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
};

SidebarContent.defaultProps = {
  toggleDrawerOpen: () => {},
  toggleDrawerClose: () => {},
  loadTransition: () => {},
  anchorEl: null,
};

export default injectIntl(SidebarContent);
