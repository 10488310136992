import React from 'react';

export const UrlAvailableLevel = {
  'tasks': {
    permissionLevel: 3
  },
  'calendar': {
    permissionLevel: 3
  },
  'users': {
    permissionLevel: 5
  },
  'reminders': {
    permissionLevel: 2
  },
  'services': {
    permissionLevel: 2
  },
  'contracts': {
    permissionLevel: 1
  },
  'clients': {
    permissionLevel: 1
  },
  'accounting': {
    permissionLevel: 4
  },
};

export const rolesPermissions = {
  superAdmin: {
    permissionLevelRange: [1, 5]
  },
  admin: {
    permissionLevelRange: [1, 5]
  },
  accounting: {
    permissionLevelRange: [3, 4]
  },
  user: {
    permissionLevelRange: [1, 3]
  },
}
