import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()((theme) => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: 0,
  },
}));

function Notification(props) {
  const { close, message } = props;
  const {
    classes
  } = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    close('crudTableDemo');
  };

  return <div></div>;
}

Notification.propTypes = {
  close: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default Notification;
