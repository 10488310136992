import Client from "./index";

export const getPayments = async (urlParams) => {
  const res = await Client.get(`/payments?limit=40&${urlParams || ''}`)
    .then((response) => response)
    .catch((error) => error);

  return res;
};

export const createPayment = async (data) => {
  const res = await Client.post(
    "/payments",
    data,
    { headers: { "Content-Type": "application/json;charset=UTF-8" } }
  )
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const makePayment = async (data, id) => {
  const res = await Client.patch(
    `/payments/${id}`,
    data,
    { headers: { "Content-Type": "application/json;charset=UTF-8" } }
  )
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const updatePayment = async (data, id) => {
  const res = await Client.patch(
    `/payments/subscriber/${id}`,
    data,
    { headers: { "Content-Type": "application/json;charset=UTF-8" } }
  )
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const deletePayment = async () => {
  const res = await Client.delete(`/payments/delete`)
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const getInfoAccounting = async (urlParams) => {
  const res = await Client.post(`/payments/info-accouting?${urlParams || ''}`)
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};
