import Client from "./index";

export const getAccountings = async (urlParams) => {
  const res = await Client.get(`/accountings?limit=40&${urlParams || ''}`)
    .then((response) => response)
    .catch((error) => error);

  return res;
};

export const getAccountingsGraph = async (urlParams) => {
  const res = await Client.get(`/accountings/graph?${urlParams || ''}`)
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const getAccountingsCircularGraph = async (urlParams) => {
  const res = await Client.get(`/accountings/graph/circular?${urlParams || ''}`)
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const createAccounting = async (data) => {
  const res = await Client.post(
    "/accountings",
    data,
    { headers: { "Content-Type": "application/json;charset=UTF-8" } }
  )
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const updateAccounting = async (data, id) => {
  const res = await Client.put(
    `/accountings/${id}`,
    data,
    { headers: { "Content-Type": "application/json;charset=UTF-8" } }
  )
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

// export const deleteAccounting = async (id) => {
//   const res = await Client.delete(`/accountings/${id}`)
//     .then((response) => {
//       if (response?.status !== 201 && response?.status !== 200)
//         throw response;
//       else {
//         return response;
//       }
//     })
//     .catch((error) => error);

//   return res;
// };

export const changeAccountingStatus = async (data, id) => {
  const res = await Client.patch(`/accountings/${id}/status`, data)
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};
