import Client from "./index";

export const getServices = async (urlParams) => {
  const res = await Client.get(`/services?limit=40&${urlParams || ''}`)
    .then((response) => response)
    .catch((error) => error);

  return res;
};

export const createServices = async (data) => {
  const res = await Client.post(
    "/services/",
    data,
    { headers: { "Content-Type": "application/json;charset=UTF-8", } }
  )
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch(error => error);

  return res;
};

export const editServicesController = async (data, id) => {
  const res = await Client.put(
    `/services/${id}`,
    data,
    { headers: { "Content-Type": "application/json;charset=UTF-8", } }
  )
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch(error => error);

  return res;
};

export const deleteServices = async (id) => {
  const res = await Client.delete(`/services/${id}`)
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch(error => error);

  return res;
};

export const enableServices = async (id) => {
  const res = await Client.patch(
    `/services/${id}/status`,
    { headers: { "Content-Type": "application/json;charset=UTF-8", } }
  )
    .then(response => {
      if (response?.status !== 201 && response?.status !== 200)
        throw response;
      else {
        return response;
      }
    })
    .catch(error => error);

  return res;
};
