import { message } from 'antd';
import Client from './index';

export const getContracts = async (urlParams) => {
  const res = await Client.get(`/contracts?limit=40&${urlParams || ''}`)
    .then((response) => response)
    .catch((error) => error);

  return res;
};

export const createContract = async (data) => {
  const res = await Client.post(`/contracts/`, data)
    .then((response) => response)
    .catch((error) => error.response);

  return res;
};

export const updateContract = async (data, formId) => {
  const res = await Client.put(`/form-contract/${formId}`, data)
    .then((response) => response)
    .catch((error) => error.response);

  return res;
};

export const createContractHistory = async (data) => {
  const res = await Client.post(`/history`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then((response) => response)
    .catch((error) => error.response);

  return res;
};

export const editStateContract = async (id, status) => {
  const res = await Client.put(`/contracts/${id}/status`, {
    status,
    cancellationReason: 'no reason',
  })
    .then((response) => response)
    .catch((error) => {
      message.error('Error state contract');
      return error.response;
    });

  return res;
};
