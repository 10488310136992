import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import StateAccounting from 'enl-context/accounting/stateAccounting'
import StateContract from 'enl-context/contracts/stateContract'
import StateClients from 'enl-context/clients/stateClients'
import StateServices from 'enl-context/services/stateServices'
import Dashboard from '../Templates/Dashboard';
import { ThemeContext } from './ThemeWrapper';
import {
  DashboardPage,
  BlankPage,
  Tasks,
  Error,
  NotFound,
  Reminders,
  Calendar,
  Services,
  Clients,
  Contracts,
  Users,
  Notifications,
  Accounting,
} from '../pageListAsync';
import { UrlAvailableLevel, rolesPermissions } from './userPermissions'

function Application(props) {
  const { history, user } = props;
  const changeMode = useContext(ThemeContext);

  const urlAvailable = (url) => {
    const { permissionLevelRange } = rolesPermissions[user?.role];
    return (
      UrlAvailableLevel[url].permissionLevel >= permissionLevelRange[0] &&
      UrlAvailableLevel[url].permissionLevel <= permissionLevelRange[1]);
  };

  return (
    <Dashboard history={history} changeMode={changeMode}>
      <StateServices>
        <StateClients>
          <StateAccounting>
            <StateContract>
              <Switch>
                {user?.role && (
                  <Route exact path='/app/notifications' component={Notifications} />
                )}
                {user?.role && urlAvailable('tasks') && (
                  <Route exact path='/app/tasks' component={Tasks} />
                )}
                {user?.role && urlAvailable('calendar') && (
                  <Route exact path='/app/calendar' component={Calendar} />
                )}
                {user?.role && urlAvailable('users') && (
                  <Route exact path='/app/users' component={Users} />
                )}
                {user?.role && urlAvailable('reminders') && (
                  <Route exact path='/app/reminders' component={Reminders} />
                )}
                {user?.role && urlAvailable('services') && (
                  <Route exact path='/app/services' component={Services} />
                )}
                {user?.role && urlAvailable('clients') && (
                  <Route exact path='/app/clients' component={Clients} />
                )}
                {user?.role && urlAvailable('contracts') && (
                  <Route exact path='/app/contracts' component={Contracts} />
                )}
                {user?.role && urlAvailable('accounting') && (
                  <Route
                    exact
                    path='/app/accounting'
                    component={() => <Accounting />}
                  />
                )}
                {user?.role && (<Route component={NotFound} />)}
              </Switch>
            </StateContract>
          </StateAccounting>
        </StateClients>
      </StateServices>
    </Dashboard>
  );
}

Application.propTypes = {
  history: PropTypes.object.isRequired,
};

const ApplicationReduxed = reduxForm({
  form: 'application',
  enableReinitialize: true,
})(Application);

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

const ApplicationMapped = connect(
  mapStateToProps,
  null
)(ApplicationReduxed);

export default ApplicationMapped;