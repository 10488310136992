import React, { useState, useEffect } from 'react';
import { Card, Badge, Tag, Space, Flex, Typography } from 'antd';
const { Text } = Typography;

export default function CardTask({ item }) {
  const [color, setColor] = useState('');

  function getRandomColor() {
    const colors = ['blue', 'red', 'green', 'yellow', 'purple', 'orange'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  useEffect(() => {
    switch (item?.priority) {
      case 'low':
        setColor('green');
        break;
      case 'medium':
        setColor('yellow');
        break;
      case 'high':
        setColor('red');
        break;
      default:
        setColor('');
    }
  }, [item?.priority]);
  return (
    <Badge.Ribbon text={item?.priority} color={color}>
      <Card
        // title={item.title}
        title={item?.name}
        size='small'
        style={{ margin: '10px 0' }}
        bordered={false}
      >
        {item?.description && item?.description.length > 50 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${item?.description.slice(0, 50)}...`,
            }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: item?.description }} />
        )}

        <Flex gap='4px 0' wrap>
          {item?.assigned &&
            item?.assigned.map((user, index) => (
              <Tag key={index} color='#108ee9' style={{ padding: '1px 15px' }}>
                <Text strong style={{ color: '#fff' }}>
                  {user.name}
                </Text>
              </Tag>
            ))}
        </Flex>
      </Card>
    </Badge.Ribbon>
  );
}
