/* eslint-disable */

import React from 'react';
import Loading from 'enl-components/Loading';
import loadable from '../utils/loadable';

export const DashboardPage = loadable(() => import('./Pages/Dashboard'), {
  fallback: <Loading />,
});
export const Table = loadable(() => import('./Pages/Table/BasicTable'), {
  fallback: <Loading />,
});
export const Form = loadable(() => import('./Pages/Forms/ReduxForm'), {
  fallback: <Loading />,
});
export const LoginFullstack = loadable(
  () => import('./Pages/UsersFullstack/Login'),
  {
    fallback: <Loading />,
  }
);
export const Login = loadable(() => import('./Pages/Users/Login'), {
  fallback: <Loading />,
});
export const ComingSoon = loadable(() => import('./Pages/ComingSoon'), {
  fallback: <Loading />,
});
export const BlankPage = loadable(() => import('./Pages/BlankPage'), {
  fallback: <Loading />,
});
export const Tasks = loadable(() => import('./Pages/Tasks'), {
  fallback: <Loading />,
});
export const Calendar = loadable(() => import('./Pages/Calendars'), {
  fallback: <Loading />,
});
export const Services = loadable(() => import('./Pages/Services'), {
  fallback: <Loading />,
});
export const Contracts = loadable(() => import('./Pages/Contracts'), {
  fallback: <Loading />,
});
export const Clients = loadable(() => import('./Pages/Clients'), {
  fallback: <Loading />,
});
export const Notifications = loadable(() => import('./Pages/Notifications'), {
  fallback: <Loading />,
});
export const Users = loadable(() => import('./Pages/UsersAdmin'), {
  fallback: <Loading />,
});
export const Accounting = loadable(() => import('./Pages/Accounting'), {
  fallback: <Loading />,
});
export const NotFound = loadable(() => import('./NotFound/NotFound'), {
  fallback: <Loading />,
});
export const Error = loadable(() => import('./Pages/Error'), {
  fallback: <Loading />,
});
export const Maintenance = loadable(() => import('./Pages/Maintenance'), {
  fallback: <Loading />,
});
export const Reminders = loadable(() => import('./Pages/Reminders'), {
  fallback: <Loading />,
});
export const Parent = loadable(() => import('./Parent'), {
  fallback: <Loading />,
});
export const NotFoundDedicated = loadable(
  () => import('./Pages/Standalone/NotFoundDedicated'),
  {
    fallback: <Loading />,
  }
);
