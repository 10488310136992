import React, { useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, DatePicker } from 'antd';
import dayjs from 'dayjs';

const InputDinamic = ({
  label1,
  label2,
  nameButton,
  type1,
  type2,
  form,
  nameForm,
  selectedItems,
  setSelectedItems,
  payInmediate,
  validateTotalPayments = {},
}) => {
  useEffect(() => {
    form.setFieldsValue({ reminders: selectedItems });
  }, [selectedItems]);

  const getValidationRules = (type) => {
    if (type === 'number') {
      return [
        {
          required: true,
          message: 'this field cannot be empty.',
        },
        {
          pattern: /^\d+$/,
          message: 'Please enter positive numbers only.',
        },
      ];
    }
    return [
      {
        required: true,
        message: 'this field cannot be empty.',
      },
    ]; // Retorna un array vacío si no es tipo 'number'
  };

  // const disabledDate = (current) => current && current < dayjs().endOf('day');

  return (
    <Form autoComplete='off' form={form}>
      <Form.List name={nameForm}>
        {(fields, { add, remove }) => (
          <div>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 8,
                  width: '100%',
                  justifyContent: 'center',
                }}
                align='baseline'
              >
                <Form.Item
                  {...restField}
                  name={[name, label1]}
                  label={label1}
                  rules={getValidationRules(type1)}
                >
                  {type1 !== 'date' ?
                    <Input
                      placeholder={label1}
                      type={type1} 
                    />:
                    <DatePicker
                      // disabledDate={disabledDate}
                      format='MM-DD-YYYY'
                    />
                  }
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, label2]}
                  label={label2}
                  rules={[getValidationRules(type2), validateTotalPayments]}
                >
                  <Input placeholder={label2} type={type2} />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => {
                    const { reminders } = form.getFieldsValue();
                    const idSelect = reminders[name]?._id;
                    if (idSelect) {
                      const itemsFilters = selectedItems?.filter(
                        (item) => item?._id !== idSelect
                      );
                      setSelectedItems(itemsFilters);
                    }
                    remove(name);
                  }}
                />
              </Space>
            ))}
            <Form.Item>
              <Button
                type='primary'
                onClick={() => add()}
                block
                ghost
                icon={<PlusOutlined />}
                disabled={payInmediate}
              >
                {nameButton}
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </Form>
  );
};
export default InputDinamic;
