import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  List,
  Input,
  Button,
  Typography,
  message,
  Row,
  Col,
  Popconfirm,  
} from 'antd';
import { addComment, deleteComment } from 'enl-controllers/task';
import { DeleteOutline } from '@mui/icons-material';

const { Text } = Typography;

const guia = {
  author: 'results.author',
  content: 'results.content',
  datetime: 'results.dateTime',
  id: 'results.id',
};
const CommentsSection = ({ edit, task, setTask }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit && task) {
      const taskWithEdit = Object.values(task).find((item) => item.id === edit);
      if (taskWithEdit) {
        setComments(taskWithEdit.comments);
      }
    }
  }, [edit, task]);
  const handleSubmit = () => {
    setLoading(true);
    if (!newComment) {
      return;
    }
    addComment({ content: newComment }, edit)
      .then(res => {
        if (res?.status === 200 || res?.status === 201) {
          const newCommentObject = res.data.results;

          // Agrega el nuevo comentario a la lista de comentarios
          const updatedComments = Array.isArray(comments)
            ? [...comments, newCommentObject]
            : [newCommentObject];
          setComments(updatedComments);
  
          // Actualiza la tarea correspondiente en el estado `tasks`
          setTask((prevTasks) => ({
            ...prevTasks,
            [edit]: {
              ...prevTasks[edit],
              comments: updatedComments,
            },
          }));
  
          setNewComment('');
          setLoading(false);
        } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
          message.error(res.response.data.message);
          setLoading(false);
        } else {
          message.error('Something unexpected has happened please try again.');
          setLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleDelete = (index) => {
    setLoading(true);
    deleteComment(edit, comments[index]._id)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          const newComments = [...comments];
          newComments.splice(index, 1);
          setComments(newComments);
  
          setLoading(false);
          message.success(res.data.message || 'omment deleted successfully');
        } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
          message.error(res.response.data.message);
          setLoading(false);
        } else {
          message.error('Something unexpected has happened please try again.');
          setLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    <div>
      <List
        header={comments ? `${comments?.length} comments` : '0 comments'}
        itemLayout='horizontal'
        dataSource={comments}
        locale={{ emptyText: 'No comments' }}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              title={
                <Row justify='space-between'>
                  <Col>
                    <Text strong>{item.author?.name}</Text>
                  </Col>
                  <Col>
                    <Text type='secondary'>
                      {dayjs(item.createdAt).format('MM-DD-YYYY HH:mm')}
                    </Text>
                  </Col>
                </Row>
              }
              description={item.content}
            />
            <Popconfirm
              title='Delete the comment'
              description='Are you sure to delete this comment?'
              onConfirm={() => handleDelete(index)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                icon={<DeleteOutline />}
                type='link'
                style={{ marginLeft: 10, color: 'red' }}
              />
            </Popconfirm>
          </List.Item>
        )}
      />
      <Input.TextArea
        style={{ marginTop: 10 }}
        rows={4}
        onChange={handleChange}
        value={newComment}
      />
      <Button onClick={handleSubmit} type='primary' style={{ marginTop: 10 }}>
        Add Comment
      </Button>
    </div>
  );
};;

export default CommentsSection;
