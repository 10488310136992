import React, { useEffect, useState } from 'react';
import useMediaQuery from 'use-media-query-hook';
import {
  DatePicker,
  TimePicker,
  Form,
  Checkbox,
  Input,
  Modal,
  Select,
  Avatar,
  Button,
  Drawer,
  Space,
  Popconfirm,
} from 'antd';
import { getUsers } from 'enl-controllers/users';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const ModalAdd = ({
  open,
  onClose,
  handleSubmit,
  form,
  idEdit,
  handleDelete,
  loading,
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const { TextArea } = Input;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsersList = async () => {
      const res = await getUsers();
      setUsers(res?.data?.results || []);
    };

    getUsersList();
  }, []);

  return (
    <Drawer
      open={open}
      title={isSmallScreen ? false: 'Event'}
      placement='right'
      closable={isSmallScreen}
      onClose={onClose}
      width={isSmallScreen ? '100%' : '40vw'}
      extra={
        <Space>
          <Button
            name='submit'
            key='submit'
            type='primary'
            loading={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
          {idEdit !== '' ? (
            <Popconfirm
              title='Delete user'
              description='Are you sure you want to delete this user?'
              onConfirm={() => handleDelete()}
              onCancel={() => console.log('cancel')}
              okText='Yes'
              cancelText='No'
            >
              <Button loading={loading} key='delete' danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
          <Button type='default' loading={loading} onClick={onClose}>
            Cancel
          </Button>
        </Space>
      }
    >
      <Form
        name='eventForm'
        form={form}
        {...formItemLayout}
        variant='outlined'
        style={{ marginTop: 60 }}
      >
        <Form.Item
          label='Title'
          name='title'
          rules={[
            {
              required: true,
              message: 'please enter the title.',
            },
          ]}
        >
          <Input placeholder='Title' name='title' />
        </Form.Item>
        <Form.Item
          label='Date'
          name='date'
          rules={[
            {
              required: true,
              message: 'please enter the date.',
            },
            () => ({
              validator(_, value) {
                if (
                  !value ||
                  dayjs(value)
                    .startOf('day')
                    .isSameOrAfter(dayjs().startOf('day'))
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The date must be greater than the current date.')
                );
              },
            }),
          ]}
        >
          <DatePicker placeholder='Date' format='MM-DD-YYYY' />
        </Form.Item>
        <Form.Item
          label='Time Start'
          name='startTime'
          rules={[
            {
              required: true,
              message: 'please enter the start time.',
            },
          ]}
        >
          <TimePicker use12Hours format='h:mm a' placeholder='Start Time' />
        </Form.Item>
        <Form.Item
          label='Time End'
          name='endTime'
          rules={[
            {
              required: true,
              message: 'please enter the end time.',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  (getFieldValue('startTime') &&
                    dayjs(value).isAfter(dayjs(getFieldValue('startTime'))))
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('End time must be greater than start time.')
                );
              },
            }),
          ]}
        >
          <TimePicker
            use12Hours
            format='h:mm a'
            placeholder='End Time'
            style={{ marginLeft: 10 }}
          />
        </Form.Item>
        <Form.Item label='Description' name={'description'}>
          <TextArea rows={3} placeholder='Description' name='description' />
        </Form.Item>

        <Form.Item label='Remenber' name='assignee'>
          <Select mode='multiple'>
            {users?.map((user, i) => (
              <Select.Option value={user._id} style={{ height: 34 }} key={i}>
                {user.name} {user.lastName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ModalAdd;
