import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useStyles from './sidebar-jss';

const rolesPermissions = {
  superAdmin: {
    permissionLevelRange: [1, 5]
  },
  admin: {
    permissionLevelRange: [1, 5]
  },
  accounting: {
    permissionLevelRange: [3, 4]
  },
  user: {
    permissionLevelRange: [1, 3]
  },
}

const LinkBtn = React.forwardRef((props, ref) => (
  <NavLink to={props.to} {...props} innerRef={ref} />
));
// eslint-disable-next-line
function MainMenu(props) {
  const [menu, setMenu] = useState([]);
  const { classes, cx } = useStyles();
  const { openSubMenu, open, dataMenu, toggleDrawerOpen, loadTransition, history, selectByPath, user } =
    props;
  const path = history?.location?.pathname;

  const filterItemsByRole = (items, role) => {
    const { permissionLevelRange } = rolesPermissions[role];
    return items.filter(item => {
      const permissionLevel = parseInt(item.permissionLevel, 10);
      return permissionLevel >= permissionLevelRange[0] && permissionLevel <= permissionLevelRange[1];
    });
  };

  useEffect(() => {
    if (user?.role) {
      setMenu([...filterItemsByRole(dataMenu, user.role)]);
    }
  }, [user]);

  const getMenus = (menuArray, paddingLevel) =>
    menuArray.map((item, index) => {
      if ((item.child || item.linkParent)) {
        return (
          <div key={index.toString()}>
            <ListItem
              button
              component={LinkBtn}
              to={item.linkParent ? item.linkParent : '#'}
              sx={{ marginLeft: !item.icon ? paddingLevel : 0 }}
              className={cx(
                classes.head,
                item.icon ? classes.iconed : '',
                path && path.includes(item.key) ? classes.opened : !selectByPath && open.indexOf(item.key) > -1 ?
                  classes.opened : ''
              )}
              onClick={() => openSubMenu(item.key, item.keyParent)}
            >
              {item.icon && (
                <ListItemIcon className={classes.icon}>
                  <Icon>{item.icon}</Icon>
                </ListItemIcon>
              )}
              <ListItemText
                classes={{ primary: classes.primary }}
                primary={item.name}
              />
              {!item.linkParent && (
                <span>
                  {open.indexOf(item.key) > -1 ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </span>
              )}
            </ListItem>
            {!item.linkParent && (
              <Collapse
                component='div'
                // className={cx( classes.nolist, item.keyParent ? classes.child : '')}
                in={open.indexOf(item.key) > -1}
                timeout='auto'
                unmountOnExit
              >
                <List className={classes.dense} component='nav'>
                  {getMenus(item.child, item.level)}
                </List>
              </Collapse>
            )}
          </div>
        );
      }
      return <div></div>
    });

  return <div>{getMenus(menu)}</div>;
}

MainMenu.propTypes = {
  open: PropTypes.array.isRequired,
  openSubMenu: PropTypes.func.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  dataMenu: PropTypes.array.isRequired,
};

const openAction = (key, keyParent) => ({
  type: 'OPEN_SUBMENU',
  key,
  keyParent,
});

const MainMenuReduxed = reduxForm({
  form: 'mainMenu',
  enableReinitialize: true,
})(MainMenu);

const mapStateToProps = (state) => ({
  open: state.ui.subMenuOpen,
  user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  openSubMenu: bindActionCreators(openAction, dispatch),
});

const MainMenuMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenuReduxed);

export default MainMenuMapped;
