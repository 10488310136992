module.exports = [
  // {
  //   key: 'account_page',
  //   name: 'Auth Page',
  //   icon: 'account_box',
  //   child: [
  //     {
  //       key: 'account_page',
  //       name: 'Sttaic Auth Page',
  //       title: true,
  //     },
  //     {
  //       key: 'login',
  //       name: 'Login',
  //       icon: 'account_box',
  //       link: '/login',
  //     },
  //     {
  //       key: 'register',
  //       name: 'Register',
  //       icon: 'border_color',
  //       link: '/register',
  //     },
  //     {
  //       key: 'reset',
  //       name: 'Reset Password',
  //       icon: 'undo',
  //       link: '/reset-password',
  //     },
  //     {
  //       key: 'account_page_firebase',
  //       name: 'Firebase Auth Page',
  //       title: true,
  //     },
  //     {
  //       key: 'login',
  //       name: 'Login',
  //       icon: 'account_box',
  //       link: '/login-firebase',
  //     },
  //     {
  //       key: 'register',
  //       name: 'Register',
  //       icon: 'border_color',
  //       link: '/register-firebase',
  //     },
  //     {
  //       key: 'reset',
  //       name: 'Reset Password',
  //       icon: 'undo',
  //       link: '/reset-password-firebase',
  //     },
  //   ],
  // },
  // {
  //   key: 'pages',
  //   name: 'Pages',
  //   icon: 'important_devices',
  //   child: [
  //     {
  //       key: 'other_page',
  //       name: 'Welcome Page',
  //       title: true,
  //     },
  //     {
  //       key: 'blank',
  //       name: 'Blank Page',
  //       icon: 'video_label',
  //       link: '/app',
  //     },
  //     {
  //       key: 'generic_page',
  //       name: 'Generic',
  //       title: true,
  //     },
  //     {
  //       key: 'dashboard',
  //       name: 'Dashboard',
  //       icon: 'settings_brightness',
  //       link: '/app/pages/dashboard',
  //     },
  //     {
  //       key: 'forms',
  //       name: 'Form',
  //       link: '/app/pages/form',
  //       icon: 'ballot',
  //     },
  //     {
  //       key: 'tables',
  //       name: 'Table',
  //       icon: 'grid_on',
  //       link: '/app/pages/table',
  //     },
  //     {
  //       key: 'maintenance',
  //       name: 'Maintenance',
  //       icon: 'settings',
  //       link: '/maintenance',
  //     },
  //     {
  //       key: 'coming_soon',
  //       name: 'Coming Soon',
  //       icon: 'polymer',
  //       link: '/coming-soon',
  //     },
  //   ],
  // },
  // {
  //   key: 'errors',
  //   name: 'Erros',
  //   icon: 'pets',
  //   child: [
  //     {
  //       key: 'errors',
  //       name: 'Errors',
  //       title: true,
  //     },
  //     {
  //       key: 'not_found_page',
  //       name: 'Not Found Page',
  //       icon: 'pets',
  //       link: '/app/pages/not-found',
  //     },
  //     {
  //       key: 'error_page',
  //       name: 'Error Page',
  //       icon: 'flash_on',
  //       link: '/app/pages/error',
  //     },
  //   ],
  // },
  {
    key: 'task',
    name: 'Tasks',
    icon: 'task',
    linkParent: '/app/tasks',
    permissionLevel: '3',
  },
  {
    key: 'calendar',
    name: 'Calendar',
    icon: 'calendar_month',
    linkParent: '/app/calendar',
    permissionLevel: '3',
  },
  {
    key: 'users',
    name: 'Users',
    icon: 'supervisor_account',
    linkParent: '/app/users',
    permissionLevel: '5',
  },
  {
    key: 'reminders',
    name: 'Reminders',
    icon: 'timer',
    linkParent: '/app/reminders',
    permissionLevel: '2',
  },
  {
    key: 'services',
    name: 'Services',
    icon: 'filter_none',
    linkParent: '/app/services',
    permissionLevel: '2',
  },
  {
    key: 'contracts',
    name: 'Contracts',
    icon: 'library_books',
    linkParent: '/app/contracts',
    permissionLevel: '1',
  },
  {
    key: 'clients',
    name: 'Clients',
    icon: 'group',
    linkParent: '/app/clients',
    permissionLevel: '1',
  },
  {
    key: 'accounting',
    name: 'Accounting',
    icon: 'auto_stories',
    linkParent: '/app/accounting',
    permissionLevel: '4',
  },

  // {
  //   key: 'menu_levels',
  //   name: 'Menu Levels',
  //   multilevel: true,
  //   icon: 'sort',
  //   child: [
  //     {
  //       key: 'level_1',
  //       name: 'Level 1',
  //       link: '/#',
  //     },
  //     {
  //       key: 'level_2',
  //       keyParent: 'menu_levels',
  //       name: 'Level 2',
  //       child: [
  //         {
  //           key: 'sub_menu_1',
  //           name: 'Sub Menu 1',
  //           link: '/#',
  //         },
  //         {
  //           key: 'sub_menu_2',
  //           name: 'Sub Menu 2',
  //           link: '/#',
  //         },
  //       ],
  //     },
  //   ],
  // },
];
