import { message } from 'antd';
import Client from './index';

export const getUser = async (id) => {
  const res = await Client.get(`/users/${id}`)
    .then((response) => response)
    .catch((error) => error);

  return res;
};

export const getUsers = async () => {
  const res = await Client.get('/users/')
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200) throw response;
      else {
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const createUser = async (data) => {
  const res = await Client.post('/users/', data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  })
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200) throw response;
      else {
        message.success('User created successfully');
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const updateUser = async (data, id) => {
  const res = await Client.put(`/users/${id}`, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  })
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200) throw response;
      else {
        message.success('User updated successfully');
        return response;
      }
    })
    .catch((error) => error);

  return res;
};

export const deleteUser = async (id) => {
  const res = await Client.delete(`/users/${id}`)
    .then((response) => {
      if (response?.status !== 201 && response?.status !== 200) throw response;
      else {
        message.success('User deleted successfully');
        return response;
      }
    })
    .catch((error) => error);

  return res;
};
