import React, { useState, useEffect } from "react";
import { message } from "antd";
import { getNotification } from 'enl-controllers/notificaction';
import notificationContext from './notificationContext';

const StateNotification = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const getNotificationData = async () => {
    const res = await getNotification();
    if (res?.status === 200 || res?.status === 201) {
      const { results } = res.data;
      setNotifications([...results]);

    } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
      setNotifications([]);
    } else {
      setNotifications([]);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(getNotificationData, 1800000);// 5000
    return () => clearInterval(intervalId);
  }, []);

  return (
    <notificationContext.Provider
      value={{
        notifications, setNotifications,
        getNotificationData
      }}
    >
      {children}
    </notificationContext.Provider>
  )

};

export default StateNotification;