import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { injectIntl } from 'react-intl';
import messageStyles from 'enl-styles/Messages.scss';
import Notifications from '@mui/icons-material/Notifications';
import { Typography } from '@mui/material';
import { getNotification } from 'enl-controllers/notificaction';
import notificationContext from 'enl-context/notification/notificationContext';
import useStyles from './header-jss';

function UserMenu(props) {
  const { notifications, setNotifications, getNotificationData } = useContext(notificationContext);
  const { classes, cx } = useStyles();
  const { dark, signOut, avatar } = props;
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const history = useHistory();
  const handleMenu = (menu) => (event) => {
    setOpenMenu(openMenu === menu ? null : menu);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  useEffect(() => {
    const aux = notifications.filter(notification => !notification.read && !notification?.color ? notification: null);
    setUnreadNotifications([...aux]);
  }, [notifications]);

  return (
    <div
      style={{ float: 'right' }}
    >
      <IconButton
        aria-haspopup='true'
        onClick={handleMenu('notification')}
        color='inherit'
        className={cx(classes.notifIcon, dark ? classes.dark : classes.light)}
        size='large'
      >
        <Badge badgeContent={unreadNotifications.length} color='secondary'>
          <Notifications />
        </Badge>
      </IconButton>
      <Menu
        id='menu-notification'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.notifMenu}
        style={{ marginTop: 30, height: 600 }}
        open={openMenu === 'notification'}
        onClose={handleClose}
      >
        {unreadNotifications.length > 0 ? (
          <div>
            {unreadNotifications.slice(0, 25).map((notification) => (
              <MenuItem
                key={notification._id}
                onClick={handleClose}
                className={messageStyles.messageInfo}
                onMouseEnter={(e) => console.log(' ')}
              >
                <ListItemText
                  primary={notification.title}
                  secondary={new Date(notification.timestamp).toLocaleString()}
                />
              </MenuItem>
            ))}

            <Divider variant='inset' />
            <Typography
              align='center'
              variant='body1'
              display='block'
              gutterBottom
              onClick={() => {
                history.push('/app/notifications');
                handleClose();
              }}
              style={{ cursor: 'pointer', width: 300 }}
            >
              show more ...
            </Typography>
          </div>
        ) : (
          <Typography
            align='center'
            variant='body1'
            display='block'
            gutterBottom
            style={{
              cursor: 'pointer',
              width: 300,
              height: 300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            No notifications available.
          </Typography>
        )}
      </Menu>

      <Button
        onClick={signOut}
        variant='outlined'
        color='error'
        style={{ marginLeft: 10, marginRight: 10 }}
      >
        Sign Out
      </Button>
    </div>
  );
}

UserMenu.propTypes = {
  signOut: PropTypes.func.isRequired,
  avatar: PropTypes.string.isRequired,
  dark: PropTypes.bool,
};

UserMenu.defaultProps = {
  dark: false,
};

export default injectIntl(UserMenu);
