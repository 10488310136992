import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Form, Card, Button, message } from 'antd';
import dayjs from 'dayjs';
import ModalAdd from './ModalAdd';

const localizer = dayjsLocalizer(dayjs);

const CalendarCustom = ({
  eventsListinit,
  generateEvent,
  deleteEvent,
  updateCalendar_,
}) => {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [idEdit, setIdEdit] = useState('');
  const [loading, setLoading] = useState('');
  const [form] = Form.useForm();
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setIdEdit('');
    form.resetFields();
  };

  const handleDelete = () => {
    setLoading(true);
    deleteEvent(idEdit)
      .then(() => {
        setEvents(events.filter((event) => event.resource.id !== idEdit));
        message.success('Event deleted successfully');
        handleClose();
        setLoading(false);
      })
      .catch((error) => {
        message.error(error);
        setLoading(false);
      });
  };
  const setTimeAndFormat = (date, time) =>
    date.set('hour', time.hour()).set('minute', time.minute()).format('HH:mm');
  const handleSubmit = () => {
    form
      .validateFields()
      .then(
        async (values) => {
          const { startTime, endTime, date, description, title, assignee } =
            values;
          const dateFormat = date.format('YYYY-MM-DD');
          const newEventObject = {
            title,
            startTime: setTimeAndFormat(date, startTime),
            endTime: setTimeAndFormat(date, endTime),
            date: dateFormat,
            usersRemember: assignee || [],
            description: description || '',
          };
          let response;

          if (idEdit === '') {
            response = await generateEvent(newEventObject);
            message.success('Event created successfully');
            setEvents([...events, response]);
            setLoading(false);
          } else {
            response = await updateCalendar_(newEventObject, idEdit);
            message.success('Event updated successfully');
            const index = events.findIndex(
              (event) => event.resource.id === idEdit
            );

            const newEvents = [...events];
            newEvents[index] = response;
            setEvents(newEvents);
            setLoading(false);
          }
          handleClose();
        } //
        ///
      )
      .catch((errorInfo) => {
        // Al menos un campo es inválido
        setLoading(false);
        message.error('Error:', errorInfo);
      });
  };

  const eventStyleGetter = (event, start, end, isSelected) => ({
    // customize your event style here
    style: {
      backgroundColor: '#3876f7',
      color: 'white',
      width: '65%',
      marginBottom: '5px',
    },
  });

  useEffect(() => {
    setEvents(eventsListinit);
  }, [eventsListinit]);

  const components = {
    event: ({ event }) => (
      <div
        onClick={() => {
          form.setFieldValue('title', event.title);
          form.setFieldValue('date', dayjs(event.start));
          form.setFieldValue('startTime', dayjs(event.start));
          form.setFieldValue('endTime', dayjs(event.end));
          form.setFieldValue('description', event.resource.description);
          form.setFieldValue(
            'assignee',
            event.resource.usersRemember.map((user) => user.id)
          );
          setIdEdit(event.resource.id);
          handleOpen();
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        <div
          style={{ fontSize: '15px', fontWeight: 'bold', marginRight: '20px' }}
        >
          {event.title}
        </div>
        <div style={{ fontSize: '10px', fontWeight: 'bold' }}>
          <span>{dayjs(event.start).format('LT')}</span>
          <span> - </span>
          <span>{dayjs(event.end).format('LT')}</span>
        </div>
      </div>
    ),
  };

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px' }}
      >
        <Button
          onClick={handleOpen}
          style={{ float: 'inline-end' }}
          type='primary'
        >
          New Event
        </Button>
      </div>
      <ModalAdd
        open={open}
        onClose={handleClose}
        handleSubmit={()=> {
          setLoading(true);
          handleSubmit();
        }}
        handleDelete={handleDelete}
        form={form}
        idEdit={idEdit}
        loading={loading}
      />
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        style={{ height: '100vh', overflowY: 'auto' }}
        eventPropGetter={eventStyleGetter}
        components={{
          ...components,
        }}
      />
    </div>
  );
};

CalendarCustom.propTypes = { intl: PropTypes.object.isRequired };

export default injectIntl(CalendarCustom);
