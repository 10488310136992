import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  message,
  DatePicker,
  Divider,
} from "antd";
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';
import { getUsers } from 'enl-controllers/users';
import CommentsSection from './comments';
import 'react-quill/dist/quill.snow.css';
import useStyles from '../Forms/user-jss';

const { Dragger } = Upload;
function ModalForm({
  edit,
  form,
  tasks,
  setTask,
}) {
  const [users, setUsers] = useState([]);
  const { classes, cx } = useStyles();

  useEffect(() => {
    const getUsersList = async () => {
      const res = await getUsers(localStorage.getItem('accessToken'));
      if (res?.status === 200 || res.status === 201) {
        setUsers(res.data.results);
      } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
        message.error(res.response.data.message);
      } else {
        message.error('Something unexpected has happened, please reload the page please.');
      }
    };

    getUsersList();
  }, []);

  // Deshabilita las fechas pasadas y la fecha actual
  const disabledDate = (current) => current && current < dayjs().endOf('day');

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i += 1) {
      result.push(i);
    }
    return result;
  };
  const disabledDateTime = () => {
    // Deshabilita las horas y minutos pasados en la fecha actual
    const hours = dayjs().hour();
    const minutes = dayjs().minute();
    return {
      disabledHours: () => range(0, 24).splice(0, hours),
      disabledMinutes: () => range(0, 60).splice(0, minutes),
    };
  };

  const normFile = (e) => e.fileList;

  return (
    <div>
      <Form
        name='taskForm'
        initialValues={{ priority: '' }}
        form={form}
        layout='vertical'
      >
        <Form.Item
          label='Title'
          name='name'
          rules={[
            {
              required: true,
              message: 'Please enter the task name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label='Priority' name='priority'>
          <Select>
            <Select.Option value=''>None</Select.Option>
            <Select.Option value='high'>High</Select.Option>
            <Select.Option value='medium'>Medium</Select.Option>
            <Select.Option value='low'>Low</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label='Due Date' name='dueDate'>
          <DatePicker
            disabledDate={disabledDate}
            // disabledTime={disabledDateTime}
            showTime
            format='MM-DD-YYYY HH:mm'
          />
        </Form.Item>

        <Form.Item
          label='Description'
          name='description'
          style={{ width: '100%', height: '300px' }}
        >
          <ReactQuill
            theme='snow'
            style={{ width: '100%', height: '200px' }}
          />
        </Form.Item>

        <Form.Item label='Assignee' name='assigned'>
          <Select
            showSearch
            mode='multiple'
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {users?.map((user, i) => (
              <Select.Option value={user._id} style={{ height: 34 }} key={i}>
                {user.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          label='Attachments'
          name='attachments'
          valuePropName='fileList'
          getValueFromEvent={normFile}
        >
          <Dragger
            name='attachments'
            multiple
            beforeUpload={() => false}
            onChange={(info) => {
              const { status } = info.file;
              if (status === 'done') 
                message.success(`${info.file.name} file uploaded successfully.`);
              else if (status === 'error') { message.error(`${info.file.name} file upload failed.`) }
            }}
          >
            <p className='ant-upload-drag-icon'><InboxOutlined /></p>
            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
            <p className='ant-upload-hint'>Support for multiple file uploads.</p>
          </Dragger>
        </Form.Item> */}
        {edit && (
          <Form.Item>
            <Divider>Comments</Divider>
            <CommentsSection task={tasks} edit={edit} setTask={setTask} />
          </Form.Item>
        )}
      </Form>
    </div>
  );
}

export default ModalForm;
