import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { message } from "antd";
import { getContracts } from 'enl-controllers/contracts';
import contractContext from './contractContext';

const Pagination = {
  pageSize: 40, // Número de elementos por página
  current: 1, // Página actual
  total: 0, // Total de elementos
};

const StateContract = ({ children }) => {
  const [dataContracts, setDataContracts] = useState([]);
  const [error, setError] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState(Pagination);
  const [dateFilter, setDataFilter] = useState(null);
  const [filterBytStatus, setFilterBytStatus] = useState();
  const [filterByContractName, setFilterByContractName] = useState();
  const [filterByServiceName, setFilterByServiceName] = useState();
  const [byNameResponsible, setByNameResponsible] = useState();

  const getUrlParams = () => `pageNumber=${pageNumber}${dateFilter ? `&startDate=${dayjs(dateFilter).format('YYYY-MM-DD')}` : ''}${filterBytStatus ? `&contractStatus=${filterBytStatus}` : ''}${filterByContractName ? `&contractName=${filterByContractName}` : ''}${filterByServiceName ? `&serviceName=${filterByServiceName}` : ''}${byNameResponsible ? `&responsibleCustomer=${byNameResponsible}` : ''}`;

  const getContractData = async () => {
    const urlParams = getUrlParams();

    const res = await getContracts(urlParams);
    if (res?.status === 200 || res?.status === 201) {
      const { data } = res.data.results;
      setDataContracts([...data]);

      setPagination({
        ...Pagination,
        current: data?.currentPage, // Página actual
        total: data?.totalItems, // Total de elementos
        onChange: (page) => setPageNumber(page),
      })
    } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
      message.error(res.response.data.message);
    } else {
      message.error('Something unexpected has happened, please reload the page please.');
    }
  };

  useEffect(() => {
    getContractData();
  }, [pageNumber, dateFilter, filterBytStatus, filterByContractName, filterByServiceName, byNameResponsible]);

  return (
    <contractContext.Provider
      value={{
        dataContracts, setDataContracts,
        error, setError,
        pagination, setPagination,
        dateFilter, setDataFilter,
        filterBytStatus, setFilterBytStatus,
        filterByContractName, setFilterByContractName,
        filterByServiceName, setFilterByServiceName,
        byNameResponsible, setByNameResponsible,
        getContractData
      }}
    >
      {children}
    </contractContext.Provider>
  )

};

export default StateContract;