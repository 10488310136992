import React, {useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { PropTypes } from 'prop-types';
import { getUser } from 'enl-controllers/users';
import StateNotification from 'enl-context/notification/stateNotification'
import { loginSuccess } from 'enl-redux/actions/authActions';
// import NotFound from '../Pages/Standalone/NotFoundDedicated';
import LoginDedicated from '../Pages/Standalone/LoginDedicated';
import Application from './Application';
import ThemeWrapper from './ThemeWrapper';
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

function App(props) {
  const { history, user, getUserOk } = props;
  const isAUserLoggedIn = () => localStorage.getItem('userId') && user?.role

  const getMyProfile = async () => {
    const res = await getUser(localStorage.getItem('userId'));
    if (res?.status === 200 || res?.status === 201)
      getUserOk(res?.data?.results);
  };

  useEffect(() => {
    if (localStorage.getItem('userId') && !user.role) getMyProfile();
    else if (isAUserLoggedIn() && !window.location.href.includes('/app/')) window.location.href = '/app/tasks';
  });

  return (
    <StateNotification>
      <ThemeWrapper>
        <Router history={history}>
          <Switch>
            {(!user?.role || !localStorage.getItem('userId')) ?
              <Route path="/" exact component={LoginDedicated} /> :
              (user?.role && localStorage.getItem('userId')) ?
                <Route path="/app" component={Application} /> :
                null
            }
            {/* <Route component={NotFound} /> */}
          </Switch>
        </Router>
      </ThemeWrapper>
    </StateNotification>
  );
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  getUserOk: PropTypes.func.isRequired,
};

const AppReduxed = reduxForm({
  form: 'app',
  enableReinitialize: true,
})(App);

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getUserOk: (user) => dispatch(loginSuccess(user)),
});


const AppMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppReduxed);

export default AppMapped;
