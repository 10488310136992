import React, { useState } from 'react';
import useMediaQuery from 'use-media-query-hook';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { message } from 'antd';
import { Grid, Paper, styled } from '@mui/material';
import { editTask } from 'enl-controllers/task';
import CardTask from './card';

const Board = ({ handleClick, columns, setColumns, tasks, setTasks, form }) => {
  const [loading, setLoading] = useState(false);
  const xs = useMediaQuery('(max-width: 540px)');
  const changeColumns = (destination, source, draggableId) => {
    // Comenzamos creando una copia de las columnas y la columna de inicio
    const startColumn = columns[source.droppableId];
    const finishColumn = columns[destination.droppableId];
    const newStartTaskIds = Array.from(startColumn.taskIds);
    const newFinishTaskIds = Array.from(finishColumn.taskIds);
    
    // Removemos la tarea de su posición original
    newStartTaskIds.splice(source.index, 1);
    
    // Si la tarea se movió dentro de la misma columna
    if (startColumn === finishColumn) {
      // Insertamos la tarea en su nueva posición
      newStartTaskIds.splice(destination.index, 0, draggableId);

      // Creamos una nueva columna con la lista de tareas actualizada
      const newColumn = {
        ...startColumn,
        taskIds: newStartTaskIds,
      };

      const updatedTasks = { ...tasks };
      newColumn.taskIds.forEach((taskId, index) => {
        updatedTasks[taskId] = {
          ...updatedTasks[taskId],
          position: draggableId === taskId ? destination.index + 1: index + 1,
          column: draggableId === taskId ? destination.droppableId: updatedTasks[taskId].column,
        };
      });
      setTasks(updatedTasks);

      // Actualizamos el estado con la nueva columna
      setColumns({
        ...columns,
        [newColumn.id]: newColumn,
      });
    } else {
      // Si la tarea se movió a otra columna
      // Insertamos la tarea en su nueva posición en la nueva columna
      newFinishTaskIds.splice(destination.index, 0, draggableId);

      // Creamos nuevas columnas con las listas de tareas actualizadas
      const newStartColumn = {
        ...startColumn,
        taskIds: newStartTaskIds,
      };

      const newFinishColumn = {
        ...finishColumn,
        taskIds: newFinishTaskIds,
      };
  

      const updatedTasks = { ...tasks };
      newFinishColumn.taskIds.forEach((taskId, index) => {
        updatedTasks[taskId] = {
          ...updatedTasks[taskId],
          position: draggableId === taskId ? destination.index + 1: index + 1,
          column: draggableId === taskId ? destination.droppableId: updatedTasks[taskId].column,
        };
      });
      setTasks(updatedTasks);

      // Actualizamos el estado con las nuevas columnas
      setColumns({
        ...columns,
        [newStartColumn.id]: newStartColumn,
        [newFinishColumn.id]: newFinishColumn,
      });
    }
  };

  const onDragEnd = (result) => {
    setLoading(true);
    const { destination, source, draggableId } = result;
    // Si el usuario arrastró el elemento fuera de las columnas, no hacemos nada
    if (!destination) {
      return;
    }
    // Si el usuario arrastró el elemento de vuelta a la misma posición, no hacemos nada
    editTask(draggableId, {
      position: result.destination.index,
      column: result.destination.droppableId,
    })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
          ) {
            return;
          }
          changeColumns(destination, source, draggableId);

          setLoading(false);
        } else if (res?.response && (res?.response?.status !== 200 || res?.response?.status !== 201)) {
          message.error(res.response.data.message);
          setLoading(false);
        } else {
          message.error('Something unexpected has happened please try again.');
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3876f7',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: 'white',
    minHeight: 300,
  }));

  return (
    <Grid container spacing={2}>
      <DragDropContext style={{backgroundColor: 'red'}} onDragEnd={onDragEnd}>
        {Object.values(columns).map((column, i) => (
          <Grid item xs={xs? 12: 3} key={i}>
            <Item>
              <Droppable droppableId={column.id} key={column.id}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ backgroundColor: '' }}
                  >
                    <strong>{column.title}</strong>
                    {column.taskIds.map((taskId, index) => {
                      const task = tasks[taskId];
                      return (
                        <Draggable
                          draggableId={task?.id}
                          index={index}
                          key={task?.id || index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={() => handleClick(task)}
                            >
                              <CardTask item={task} />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Item>
          </Grid>
        ))}
      </DragDropContext>
    </Grid>
  );
}

export default Board;
