import React, { useState, useEffect } from "react";
import { getServices } from 'enl-controllers/services';
import servicesContext from './servicesContext';

const Pagination = {
  pageSize: 40, // Número de elementos por página
  current: 1, // Página actual
  total: 0, // Total de elementos
};

const StateServices = ({ children }) => {
  const [services, setServices] = useState([]);
  const [pagination, setPagination] = useState(Pagination);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterByName, setFilterByName] = useState('');

  const getUrlParams = () => `pageNumber=${pageNumber}${filterByName ? `&name=${filterByName}` : ''}`

  const getServicesData = async () => {
    const urlParams = getUrlParams();
    const res = await getServices(urlParams);
    if (res?.status === 200 || res?.status === 201) {
      const { data, currentPage, totalItems } = res.data.results;
      setServices([...data]);

      setPagination({
        ...Pagination,
        current: currentPage, // Página actual
        total: totalItems, // Total de elementos
        onChange: (page) => setPageNumber(page),
      })
    }
  };

  useEffect(() => {
    getServicesData();
  }, [pageNumber, filterByName]);

  return (
    <servicesContext.Provider
      value={{
        services, setServices,
        pagination,
        filterByName, setFilterByName,
        getServicesData
      }}
    >
      {children}
    </servicesContext.Provider>
  )

};

export default StateServices;