import React, { useState, useEffect } from "react";
import { getClients } from 'enl-controllers/clients';
import clientsContext from './clientsContext';

const Pagination = {
  pageSize: 40, // Número de elementos por página
  current: 1, // Página actual
  total: 0, // Total de elementos
};

const StateClients = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [copyClients, setCopyClients] = useState([]);
  const [pagination, setPagination] = useState(Pagination);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterByName, setFilterByName] = useState('');

  const getUrlParams = () => `pageNumber=${pageNumber}${filterByName ? `&name=${filterByName}` : ''}`

  const getClientsData = async () => {
    const urlParams = getUrlParams();
    const res = await getClients(urlParams);

    if (res?.status === 200 || res?.status === 201) {
      const { data, currentPage, totalItems } = res.data.results;
      setClients([...data]);
      if (copyClients.length === 0) setCopyClients([...data]);

      setPagination({
        ...Pagination,
        current: currentPage, // Página actual
        total: totalItems, // Total de elementos
        onChange: (page) => setPageNumber(page),
      })
    }
  };

  useEffect(() => {
    getClientsData();
  }, [pageNumber, filterByName]);

  return (
    <clientsContext.Provider
      value={{
        clients, setClients,
        pagination,
        filterByName, setFilterByName,
        copyClients, setCopyClients,
        getClientsData,
      }}
    >
      {children}
    </clientsContext.Provider>
  )

};

export default StateClients;