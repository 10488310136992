import React, { useEffect, useState } from 'react';
import useMediaQuery from 'use-media-query-hook';
import { Button, Drawer, Form, message, Popconfirm, Space } from 'antd';
import dayjs from 'dayjs';
import TaskBoard from './TaskBoard';
import ModalForm from './formTask';

const initialValuesForm = {
  name: '',
  priority: '',
  description: '',
  assigned: [],
  dueDate: '',
  reminderTime: '',
};
const UseTaskTable = ({
  columns,
  tasks,
  setColumns,
  generateTask,
  deleteTask,
  setTasks,
  formError,
  setFormError,
  loading,
  setDueDateAct,
  dueDateAct,
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [open, setOpen] = useState(false);
  const [edit, setedit] = useState('');
  const [form] = Form.useForm();

  const handleOpen = () => {
    setOpen(true);
  };
  const onClickTask = (id) => {
    setOpen(true);
    setedit(id);
  };

  const handleClick = (task) => {
    const incomingData = task?.assigned?.map((user) => user?.id || user?._id) || [];
    const dueDate = task.dueDate ? dayjs(task.dueDate) : '';
    setDueDateAct(dueDate);
    form.setFieldsValue({
      ...task,
      assigned: incomingData,
      dueDate,
    });
    onClickTask(task.id);
  };

  const handleClose = () => {
    setOpen(false);
    setedit('');
    setFormError(false);
    setDueDateAct('');
    form.setFieldsValue(initialValuesForm);
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();

    if (dueDateAct === form.getFieldsValue().dueDate) {
      delete values.dueDate;
    }

    form
      .validateFields()
      .then(async () => {
        await generateTask(values, edit);
        // if (edit) message.success('Task has been updated successfully');
        // else message.success('Task has been create successfully');

        handleClose();
      })
      .catch((errorInfo) => {
        // Al menos un campo es inválido
        message.error(errorInfo);
      });
  };

  const confirm = async (e) => {
    const successfulProcess = await deleteTask(edit);
    if (successfulProcess) handleClose();
    message.success('Task deleted successfully');
  };

  return (
    <div>
      <div>
        <Button
          style={{ float: 'inline-end', marginBottom: 30 }}
          type='primary'
          onClick={handleOpen}
        >
          Create Task
        </Button>
      </div>
      <TaskBoard
        // onClickTask={onClickTask}
        handleClick={handleClick}
        columns={columns}
        tasks={tasks}
        setTasks={setTasks}
        setColumns={setColumns}
      />
      <Drawer
        open={open}
        title={isSmallScreen ? false: 'Task'}
        placement='right'
        closable={isSmallScreen}
        onClose={handleClose}
        width={isSmallScreen ? '100%' : '40vw'}
        extra={
          <Space>
            <Button
              name='submit'
              key='submit'
              type='primary'
              onClick={handleSubmit}
              loading={loading}
            >
              Save
            </Button>
            {edit !== '' ? (
              <Popconfirm
                title='Delete the task'
                description='Are you sure to delete this task?'
                onConfirm={confirm}
                okText='Yes'
                cancelText='No'
              >
                <Button danger loading={loading}>
                  Delete
                </Button>
              </Popconfirm>
            ) : null}
            <Button type='default' loading={loading} onClick={handleClose}>
              Cancel
            </Button>
          </Space>
        }
      >
        <ModalForm
          edit={edit}
          form={form}
          initialValuesForm={initialValuesForm}
          tasks={tasks}
          setTask={setTasks}
          formError={formError}
          setFormError={setFormError}
        />
      </Drawer>
    </div>
  );
};;

export default UseTaskTable;
